@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .sidebar-scrollbar::-webkit-scrollbar {
    width: 13px;
    height: 10px;
    transition: border 0.3s ease; /* Add a smooth transition effect */
  }

  .sidebar-scrollbar::-webkit-scrollbar-track {
    /* border-radius: 100vh; */
    background: transparent;
  }

  .sidebar-scrollbar::-webkit-scrollbar-thumb {
    background: #8d99ae;
    border-radius: 10px;
    border: solid transparent;
    background-clip: padding-box;
  }

  .sidebar-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #8d99ae;
    border: 50px solid #8d99ae;
  }

  .admissionForm-scrollbar::-webkit-scrollbar {
    width: 13px;
    height: 10px;
    transition: border 0.3s ease; /* Add a smooth transition effect */
  }

  .admissionForm-scrollbar::-webkit-scrollbar-track {
    /* border-radius: 100vh; */
    background: white;
    border-radius: 10px;
  }

  .admissionForm-scrollbar::-webkit-scrollbar-thumb {
    background: #45b1a8b3;
    border-radius: 10px;
    border: solid transparent;
    background-clip: padding-box;
  }

  .admissionForm-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #45b1a8b3;
    border: 50px solid #45b1a8b3;
  }

  .table-scrollbar::-webkit-scrollbar {
    width: 13px;
    height: 10px;
    transition: border 0.3s ease; /* Add a smooth transition effect */
  }

  .table-scrollbar::-webkit-scrollbar-track {
    /* border-radius: 100vh; */
    background: #f1f1f1;
    /* border-radius: 10px; */
  }

  .table-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    /* border-radius: 10px; */
    border: solid transparent;
    background-clip: padding-box;
  }

  .table-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
    border: 50px solid #555;
  }

  .table-scrollbar::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 1.9rem;
  }

  .table-scrollbar.scrollable-with-monthdays::-webkit-scrollbar-track-piece:start {
    margin-top: 4.2rem; /* Adjusted value when month & days are present */
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: math;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow-x: hidden; /* Prevent extra scrollbars */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
