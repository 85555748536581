.login-sec .wrapper {
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  border: 3px solid rgb(255 203 100 / 50%);
  backdrop-filter: blur(8px);
}

.input-field {
  position: relative;
  border-bottom: 2px solid rgb(255 203 100 / 50%);
  margin: 20px 0;
}

.input-field label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: white;
  font-size: 16px;
  pointer-events: none;
  transition: 0.15s ease;
  font-weight: bold;
  font-size: 18px;
}

.input-field input {
  width: 100%;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 18px;
  color: white;
}

.pswd-hide-icon {
  display: none;
  float: right;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.input-field input:focus ~ label,
.input-field input:valid ~ label {
  font-size: 1.03rem;
  top: 10px;
  transform: translateY(-120%);
}

.input-field input:focus ~ .pswd-hide-icon,
.input-field input:valid ~ .pswd-hide-icon {
  display: block;
}

.forget {
  margin: 25px 0 35px 0;
  color: white;
  font-weight: bold;
}

.wrapper .forget a {
  color: white;
  text-decoration: none;
}

.wrapper .forget a:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.wrapper button {
  background: white;
  color: #000;
  font-weight: 600;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 3px;
  border: 2px solid transparent;
  transition: 0.3s ease;
}

.wrapper button:hover {
  color: white;
  background: rgb(255 203 100 / 70%);
}
